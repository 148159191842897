<template>
  <div class="outer">
    <div
      v-if="currentBanner"
      class="banner"
      :class="{ loaded: imageLoaded, unloaded: !imageLoaded }"
    >
      <img
        :src="currentBanner"
        alt="Banner Image"
        @load="imageLoaded = true"
        @error="imageLoaded = false"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref, onMounted, onUnmounted, computed, watchEffect } from "vue";
import { useAsyncData } from "nuxt/app";

const banners = ref<string[]>([]);
const currentIndex = ref(0);
const imageLoaded = ref(false);

// Fetch banners
const { data } = useAsyncData("banners", () => $fetch("/api/banner"));

watchEffect(() => {
  if (data.value) {
    banners.value = data.value as string[];
    currentIndex.value = 0; // Reset index when data is updated
  }
});

// Setup banner rotation
let interval: any;
onMounted(() => {
  interval = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % banners.value.length;
    imageLoaded.value = false; // Reset image loading state
  }, 3000);
});

onUnmounted(() => {
  clearInterval(interval);
});

// Computed property to get the current banner
const currentBanner = computed(() => banners.value[currentIndex.value]);
</script>
<style scoped>
.banner {
  width: 100%; /* Container takes full width */
  max-width: 900px; /* Max width of the banner container */
  margin: 0 auto; /* Center the banner */
  transition: height 0.5s ease-in-out;
  display: flex; /* Use flexbox for centering */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.banner img {
  max-width: 100%; /* Max width of the image is 100% of the container */
  height: auto; /* Maintain aspect ratio */
}

.outer {
  @media (min-width: 601px) {
    min-height: 150px;
  }
}
</style>
